export const siteData = {
  "name": "BARMELO",
  "address": "30 AVENUE DU GENERAL GALLIENI,",
  "postCode": "92000",
  "city": "nanterre",
  "phone": "0634150733",
  "url": "https://barmelo.store",
  "urlSub": "barmelo.store",
  "price": "price_1Q54arE9cmTcmrv6Z0Kstfze",
  "pk": "pk_live_51Q54U5E9cmTcmrv68BZXnHOHDdz6YEuLguXKMHYKegtKDCpWNAFwN6JmqnIaghUndRzczawiUWUaO3P7EsP89KqV00cKZzmeGI",
  "sk": "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"
};